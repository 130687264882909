.App {
  display: flex;
  width: 100vw;
  justify-content: space-between;

  .ReactMarkdown_container {
    width: 50vw;
    max-width: 50%;
    font-family: Helvetica, Arial, sans-serif;

    .ReactMarkdown {
      margin: 10% 10%;

      h1 {
        margin-top: 1.2em;

      }

      h2 {
        margin-top: 0.3em;
      }

      blockquote {
        font-family: serif;
        font-style: italic;
        padding-left: 2em;
        font-size: 1.2em;
        background: #f5f5f5;
        margin: 20px 0;
      }
    }
    
  }
}
